// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-examples-js": () => import("./../../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("./../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-templates-about-page-js": () => import("./../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-brothers-page-js": () => import("./../../src/templates/brothers-page.js" /* webpackChunkName: "component---src-templates-brothers-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-linktree-page-js": () => import("./../../src/templates/linktree-page.js" /* webpackChunkName: "component---src-templates-linktree-page-js" */),
  "component---src-templates-news-page-js": () => import("./../../src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-null-js": () => import("./../../src/templates/null.js" /* webpackChunkName: "component---src-templates-null-js" */),
  "component---src-templates-philantrophy-page-js": () => import("./../../src/templates/philantrophy-page.js" /* webpackChunkName: "component---src-templates-philantrophy-page-js" */)
}

